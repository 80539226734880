@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

* {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 256px;
  text-align: center;
  border: 10px solid #101116;
  background: #101116;
  border-radius: 5px;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(60px, auto);
}

input[type="text"] {
  height: 75px;
  width: 249px;
  background-color: #10111600;
  color: #ffffff;
  text-align: right;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1px;
}

#clear {
  grid-column: 1/3;
  grid-row: 1;
  color: #262834;
}

#result {
  grid-column: 3/5;
  grid-row: 5;
  color: #262834;
}

#backspace {
  color: #262834;
}

button {
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #262834;
  color: #ffffff;
  font-weight: 500;
}

button:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
  box-shadow: 0 0 11px rgba(238, 243, 240, 0.856);
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

.highlight {
  background: #56cbdb;
}
